import React from 'react'
import {
  DsButtonBase,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

import LOGO_IMAGE from '~/src/Assets/LOGO_IMAGE'

interface ILogoWithNavProps extends IWithRouterProps {
  onClick?: () => void
}

const LogoWithNav: React.FC<ILogoWithNavProps> = props => {
  const { navigateTo, onClick } = props

  function handleClick() {
    navigateTo(APP_ROUTES.LANDING.pathname)
    onClick && onClick()
  }

  return (
    <DsButtonBase onClick={handleClick}>
      <DsStack
        direction='row'
        spacing='var(--ds-spacing-glacial)'
        sx={{ alignItems: 'center', py: 'var(--ds-spacing-bitterCold)' }}
      >
        <DsImage
          aspectRatio={1 / 1}
          srcSet={LOGO_IMAGE}
          WrapperProps={{ sx: { height: '48px', width: '48px' } }}
          style={{ display: 'block' }}
        />

        <DsTypography
          component='div'
          variant='headingBoldLarge'
          sx={{
            fontWeight: 'var(--ds-typo-fontWeightLight)'
          }}
        >
          GriffyReads
        </DsTypography>
      </DsStack>
    </DsButtonBase>
  )
}

export default withRouter(LogoWithNav)
