import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from './TYPES'

const HU_MAIN_LAYOUT_PAGE_CONSTANTS: T_MAIN_LAYOUT_PAGE_CONSTANTS = {
  LINK_PRIVACY_POLICY_CTA_TEXT: 'Adatvédelmi tájékoztató',
  LINK_TERMS_CTA_TEXT: 'Felhasználási feltételek',
  NAVIGATION_HEADER: 'Navigációk',
  LINK_HOME_CTA_TEXT: 'Főoldal',
  LINK_FAQ_CTA_TEXT: 'GYIK',
  LINK_PRICING_CTA_TEXT: 'Előfizetés',
  LINK_CONTACT_US_CTA_TEXT: 'Kapcsolat',
  LINK_LOGIN_CTA_TEXT: 'Bejelentkezés'
}

export default HU_MAIN_LAYOUT_PAGE_CONSTANTS
